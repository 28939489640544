/**
 * A composable function that provides methods for fetching menus from a WordPress site.
 *
 * @returns {Object} An object containing the `getMenu` method.
 */
export default () => {
  const config = useRuntimeConfig().public
  const ssApi = config.apiSs
  const cacheExpiration = 60 * 60 * 1000 // 1 hour

  // COMMENT: "Location" is the term used in WP, currently in WP we've created these locations:
  // Main-menu = Primary
  // Secondary-menu = secondary
  // Footer = footer

  // List of menu endpoints if needed
  // http://wsa-backend.test/wp-json/wp-api-menus/v2/menus
  // http://wsa-backend.test/wp-json/wp-api-menus/v2/menus
  // http://wsa-backend.test/wp-json/wp-api-menus/v2/menu-locations

  /**
   * Fetches the menu for the specified location.
   *
   * @param {string} location - The location of the menu.
   * @returns {Promise} A promise that resolves to the fetched menu.
   */
  const getMenu = async (location, nuxtApp) => {
    return useFetch(`${ssApi}/menu/${location}`, {
      key: `${ssApi}/menu/${location}`,
      immediate: true,
      lazy: false,
      transform(data) {
        return {
          ...[data],
          fetchedAt: new Date()
        }

      },
      getCachedData(key) {
        const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key]
        // If data is not fetched yet
        if (!data) {
          // Fetch the first time
          return
        }

        // Is the data too old?
        const expirationDate = new Date(data.fetchedAt)
        expirationDate.setTime(expirationDate.getTime() + cacheExpiration)
        const isExpired = expirationDate.getTime() < Date.now()
        if (isExpired) {
          // Refetch the data
          return
        }

        return data
      },
    })
  }

  return {
    getMenu,
  }
}
